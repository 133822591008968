import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 14 16",
  fill: "#909294",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      fill: "inherit",
      d: "M9,2h3.5C13.3,2,14,2.7,14,3.5v11c0,0.8-0.7,1.5-1.5,1.5h-11C0.7,16,0,15.3,0,14.5v-11C0,2.7,0.7,2,1.5,2H5 c0-1.1,0.9-2,2-2S9,0.9,9,2z M7.8,2c0-0.4-0.3-0.8-0.8-0.8S6.2,1.6,6.2,2c0,0.4,0.3,0.8,0.8,0.8S7.8,2.4,7.8,2z M3.5,6 C3.2,6,3,6.2,3,6.5S3.2,7,3.5,7h5C8.8,7,9,6.8,9,6.5S8.8,6,8.5,6H3.5z M3,9c0-0.3,0.2-0.5,0.5-0.5h7C10.8,8.5,11,8.7,11,9 s-0.2,0.5-0.5,0.5h-7C3.2,9.5,3,9.3,3,9z M3.5,11C3.2,11,3,11.2,3,11.5S3.2,12,3.5,12h3C6.8,12,7,11.8,7,11.5S6.8,11,6.5,11H3.5z"
    }, null, -1)
  ])))
}
export default { render: render }